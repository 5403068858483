/// <reference path="app.ts" />
interface iForm {
  form: HTMLFormElement;
  url: string;
  dataArray: any;
  form_type: string;
  validation(): boolean;
  ajax(): void;
}

class Form implements iForm {
  form: HTMLFormElement;
  url:string;
  dataArray: any;
  form_type: string;
  constructor (form:HTMLFormElement, url:string, dataArray:any, form_type:string) {
    this.form = form;
    this.url = url;
    this.dataArray = dataArray;
    this.form_type = form_type;
  }

  validation() : boolean {
    let valid:boolean = true;

    $.each($(this.form).find('input.required'), function(i, val) {
      if($(this).val() == "") {
        valid = false; 
        $(this).closest('label').addClass('error');
      } else {
        $(this).closest('label').removeClass('error'); 
      }
     });

    return valid;
  }

  ajax(): void {
    let valid:boolean = this.validation();
    if(valid) {
      let goal:string = this.form_type;

      $.ajax({
        type: "POST",
        url: this.url,
        processData: false,
        contentType: false,
        data: this.dataArray
      }).done(function(res) {
        console.log(res);
        if(res == 0) {
          showSuccess(); 
          yaCounter48128651.reachGoal(goal);  
        } else if (res == 'robot') {
          alert("Вы не подтвердили, что не робот!");
        } else {
          alert("Что-то пошло не так, повторите попытку позднее! (Возможно, нет подключения к интернет)");
        }
        
        grecaptcha.reset();
      }).fail(function(res) {
        console.log(res);
        grecaptcha.reset();
       });
    } else {
      
      grecaptcha.reset();
    }
  }
}


let submitedForm:any;
let token: string;

function onSubmitForm(a) {
    token = a;
    submitedForm.submit();
}

function detectForm(a) {
    submitedForm = $(a).closest('form');
}


$(function() {
    $('form').submit(function(e) {
        e.preventDefault(); 
      
        let dataArray = new FormData(this);
        dataArray.append('type', $(this).attr('name'));
        dataArray.append('g-recaptcha-response', token);
        let form_type = $(this).attr('name');
        let url = $(this).attr('action');
        let form:Form = new Form(this, url, dataArray, form_type);
        let response = form.ajax();
    });
});      